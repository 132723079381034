// // src/components/Header.jsx
// import React from 'react';
// import logo from './../assets/logo3.png';
// import './../styles/header.css';
// const Header = () => {
//   return (
//     // <header className="bg-blue-900 text-white py-4">
//     //   <div className="container mx-auto flex justify-between items-center">
//     //     {/* <h1 className="text-2xl font-bold">QuickGig</h1> */}
//     //     <img src={logo} alt="Logo" style={{ height: '50px', width: 'auto' }} />
//     //     <nav>
//     //        <ul className="flex space-x-4 md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700">
//     //          {/* <ul className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center"> */}
//     //          <li><a href="#" className="hover:underline">Home</a></li>
//     //          <li><a href="#services" className="hover:underline">Services</a></li>
//     //          <li><a href="#about" className="hover:underline">About Us</a></li>
//     //          <li><a href="#contact" className="hover:underline">Contact</a></li>
//     //        </ul>
//     //      </nav>
//     //    </div>
//     //  </header>

// <header className="bg-gray-800 text-white p-4 flex items-center justify-between">
// <div className="flex items-center">
//   <img src={logo} alt="Logo" className="h-10 w-auto mr-4" />
//   <h1 className="text-xl font-semibold">My Website</h1>
// </div>
// <nav className="hidden md:flex space-x-4">
//   <a href="#" className="hover:text-gray-400">Home</a>
//   <a href="#" className="hover:text-gray-400">About</a>
//   <a href="#" className="hover:text-gray-400">Services</a>
//   <a href="#" className="hover:text-gray-400">Contact</a>
// </nav>
// <button className="md:hidden text-gray-300 focus:outline-none">
//   {/* Menu icon for mobile */}
//   <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
//   </svg>
// </button>
// </header>
//   );
// };

// export default Header;


import React, { useState,useEffect } from 'react';
import logo from '../assets/logo3.png'; // Adjust the path as necessary

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      e.preventDefault();
      const href = e.target.getAttribute('href');
      if (href && href.startsWith('#')) {
        const targetElement = document.querySelector(href);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    };

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', handleScroll);
    });

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener('click', handleScroll);
      });
    };
  }, []);


  return (
    <header className="bg-blue-900 text-white py-4 flex items-center justify-between mx-auto">
      <div className="flex items-center">
        <h1 className="text-2xl font-semibold ml-4">QuickGig</h1>
      </div>
      <nav className={`md:flex ${isOpen ? 'block' : 'hidden'} md:space-x-4`}>
        <a href="#home" className="block md:inline hover:text-gray-400">Home</a>
        <a href="#about" className="block md:inline hover:text-gray-400">About</a>
        <a href="#services" className="block md:inline hover:text-gray-400">Services</a>
        <a href="#contact" className="block md:inline hover:text-gray-400 mr-4">Contact</a>
      </nav>
      <button
        className="md:hidden text-gray-300 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* Menu icon for mobile */}
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
    </header>
        // <header className="bg-blue-900 text-white py-4">
    //   <div className="container mx-auto flex justify-between items-center">
    //     {/* <h1 className="text-2xl font-bold">QuickGig</h1> */}
    //     <img src={logo} alt="Logo" style={{ height: '50px', width: 'auto' }} />
    //     <nav>
    //        <ul className="flex space-x-4 md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700">
    //          {/* <ul className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center"> */}
    //          <li><a href="#" className="hover:underline">Home</a></li>
    //          <li><a href="#services" className="hover:underline">Services</a></li>
    //          <li><a href="#about" className="hover:underline">About Us</a></li>
    //          <li><a href="#contact" className="hover:underline">Contact</a></li>
    //        </ul>
    //      </nav>
    //    </div>
    //  </header>
  );
};

export default Header;
