// src/components/Home.jsx
import React from 'react';

const Home = () => {
    return (
        // <section id="home" className="bg-gray-100 py-12">
        //   <div className="container mx-auto text-center">
        //     <h2 className="text-4xl font-bold mb-4">Welcome to QuickGig</h2>
        //     <p className="text-lg mb-8">Your trusted partner in Human Resource Consulting.</p>
        //     <a 
        //       href="mailto:hr@quickgig.in?subject=Inquiry from Website"
        //       className="bg-blue-900 text-white px-6 py-3 rounded-md hover:bg-blue-700"
        //     >
        //       Get in Touch
        //     </a>
        //   </div>
        // </section>
        //     <section className="text-gray-600 body-font">
        //     <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        //       <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
        //         <img className="object-cover object-center rounded" alt="hero" src="/images/slide1.jpg" />
        //       </div>
        //       <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        //         <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Welcome to QuickGig
        //         </h1>
        //         <p className="mb-8 leading-relaxed">Your trusted partner in Human Resource Consulting.</p>
        //         <div className="flex justify-center">
        //           <button className="bg-blue-900 text-white px-6 py-3 rounded-md hover:bg-blue-700">Get In Touch</button>
        //         </div>
        //       </div>
        //     </div>
        //   </section>

        <div className="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
            <div className="absolute inset-0">
                <img src="/images/slide1.jpg" alt="Background Image" className="object-cover object-center w-full h-full" />
                <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>

            <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
                <h1 className="text-5xl font-bold leading-tight mb-4">Welcome to QuickGig</h1>
                <p className="text-lg text-gray-300 mb-8">Your trusted partner in Human Resource Consulting.</p>
                <a href="mailto:hr@quickgig.in?subject=Inquiry from Website" className="bg-blue-900 text-white px-6 py-3 rounded-md hover:bg-blue-700 text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Get in touch</a>
            </div>
        </div>


    );
};

export default Home;