// src/components/Services.jsx
import React from 'react';
import '../styles/flipAnimation.css';

const Services = () => {
  return (
    <section id="services" className="py-12 m-5">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold">Permanent Staffing Solutions</h3>
              </div>
              <div className="flip-card-back">
              <p>We specialize in finding the right talent for permanent roles in organizations. Our team of experienced recruiters works closely with clients to understand their long-term staffing needs and sources highly skilled professionals who fit the company culture and objectives.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold">Executive Search and Leadership Hiring</h3>
              </div>
              <div className="flip-card-back">
              <p>Our executive search services focus on sourcing and placing senior-level executives and leadership positions across various sectors. We leverage our extensive network and deep industry knowledge to identify top talent capable of driving strategic growth.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold">Recruitment Process Outsourcing (RPO)</h3>
              </div>
              <div className="flip-card-back">
              <p>We offer end-to-end Recruitment Process Outsourcing services, allowing companies to outsource their entire recruitment process to us. From sourcing and screening to onboarding, we handle it all, ensuring a streamlined and efficient hiring process.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold">Bulk Hiring Solutions</h3>
              </div>
              <div className="flip-card-back">
                <p>For organizations with large-scale hiring needs, such as BPOs or expanding businesses, we provide bulk hiring solutions. Our team is adept at managing high-volume recruitment drives to deliver quality candidates within tight timelines.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold">Specialized Recruitment for Niche Roles</h3>
              </div>
              <div className="flip-card-back">
                <p>We focus on niche roles that require specialized skills and experience in domains like IT, Banking, NBFC, Insurance, Pharma, and BPO. Our domain-specific recruiters have the expertise to find candidates with unique skill sets that meet the specific needs of our clients.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold">Diversity and Inclusion Hiring</h3>
              </div>
              <div className="flip-card-back">
                <p>We assist organizations in building diverse and inclusive teams. Our recruitment strategies are designed to promote equal opportunity and representation in the workplace, ensuring a balanced and innovative workforce.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
