// src/components/About.jsx
import React from 'react';

const About = () => {
    return (
        <section id="about" className="bg-gray-100 py-12">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6">About Us</h2>
                <p className="text-lg text-gray-600 body-font">Welcome to QuickGig – Where Talent Meets Opportunity


                    At QuickGig, we are more than just a recruitment consultancy; we are your trusted partners in talent acquisition. With a team of passionate and experienced professionals, we specialize in connecting top-tier talent with leading organizations across IT, Banking, NBFC, Insurance, Pharma, and BPO domains.

                    Our approach is simple yet powerful: we blend industry expertise with a deep understanding of the dynamic job market to deliver exceptional results. We pride ourselves on our ability to not just fill roles, but to build teams that drive growth, innovation, and success for our clients.What sets us apart? It’s our commitment to excellence, our agility in finding the right fit, and our relentless pursuit of creating meaningful connections between candidates and companies.

                    Whether you are looking for the next game-changer for your organization or exploring new career opportunities, QuickGig is here to make the perfect match.Join hands with us and experience recruitment redefined—because at QuickGig, we believe that the right talent can transform the future.</p>
            </div>
        </section>
    );
};

export default About;
