// src/components/Contact.jsx
import React from 'react';

const Contact = () => {
    return (
        <section id="contact" className="bg-gray-100 py-12">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
                <p className="text-lg mb-6 text-gray-600 body-font">We'd love to hear from you! Get in touch with us at   
                <a className="text-blue-900 hover:underline" href="mailto:hr@quickgig.in?subject=Inquiry from Website"> hr@quickgig.in</a> or call us on <a className="text-blue-900 hover:underline" href="tel:+919098399781">+919098399781 </a>
                    to get more information</p>
            </div>
        </section>
    );
};

export default Contact;
